<template>
    <b-container fluid>
        <div class="card card-primary">
            <div class="card-header">
                <h3 class="card-title">Lieferantenbestellung: <template v-if="notEmptyObject(order)">{{ order.ordernumber }}</template></h3>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-md-3" style="display: flex; flex-direction: column;">
                        <div class="card card-primary card-outline" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                            <div class="card-header">
                                <h3 class="card-title">Stammdaten</h3>
                            </div>
                            <div class="card-body" v-if="notEmptyObject(order)">
                                {{ order.distributor.name }}<br>
                                {{ order.distributor.street }} {{ order.distributor.house_number }}<br>
                                {{ order.distributor.zipcode }} {{ order.distributor.city }}<br>
                                <br>
                                <!-- <span class="text-danger">ACHTUNG: Bestellung erst speichern bevor diese als bestellt markiert wird.<br> Die Bestellung muss nur als bestellt markiert, wenn diese manuell ausgeführt wurde. (Telefon, Outlook, Website)</span><br> -->
                                <span id="tooltip-ads" class="text-danger">ACHTUNG: Bestellung erst speichern bevor diese als bestellt markiert wird. <i class="fas fa-info-circle"></i></span>
                                <b-tooltip target="tooltip-ads" triggers="hover">
                                    Die Bestellung muss nur als bestellt markiert werden, wenn diese manuell ausgeführt wurde. (Telefon, Outlook, Website)
                                </b-tooltip><br>  
                                <button type="button" class="btn btn-success mr-1" @click="setOrdered" :disabled="order.items.length <= 0">Als bestellt markieren</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" style="display: flex; flex-direction: column;">
                        <div class="card card-primary card-outline" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                            <div class="card-header">
                                <h3 class="card-title">Lieferdatum</h3>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Lieferdatum</label>
                                    <input type="date" class="form-control form-control-sm" v-model="order.wishdate">
                                </div>

                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" style="display: flex; flex-direction: column;">
                        <div class="card card-primary card-outline" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                            <div class="card-header">
                                <h3 class="card-title">Allgemeine Infos</h3>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Infos an den Lieferanten</label>
                                    <textarea class="form-control form-control-sm" v-model="order.info" rows="5"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" style="display: flex; flex-direction: column;">
                        <div class="card card-primary card-outline" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                            <div class="card-header">
                                <h3 class="card-title">Lieferanten Infos</h3>
                            </div>
                            <div class="card-body">
                                <h6><strong>Lieferant:</strong> {{ order.distributor.name }}</h6>
                                <h6>
                                    <template v-if="order.distributor.skonto != null">
                                        <strong>Skonto:</strong> {{ order.distributor.skonto / 100 | toPercent }} 
                                    </template>

                                    <template v-if="order.distributor.skonto == null">
                                        <strong>Skonto:</strong> - Kein Skonto -
                                    </template>
                                </h6>
                                <h6>
                                    <template v-if="order.distributor.min_order_amount != null">
                                        <strong>Mindestbestellwert:</strong> {{ order.distributor.min_order_amount | toCurrency }}
                                    </template>

                                    <template v-if="order.distributor.min_order_amount == null">
                                        <strong>Mindestbestellwert:</strong> - Kein Mindestbestellwert -
                                    </template>
                                </h6>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="order.distributor.orderinfo != null">
                    <div class="col-md-12">
                        <div class="card card-primary card-outline">
                            <div class="card-header">
                                <h2 class="card-title">Bestellinfos</h2>
                            </div>
                            <div class="card-body">
                                <span v-html="order.distributor.orderinfo"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="card card-primary card-outline" style="height: 45vh">
                            <div class="card-header">
                                <h2 class="card-title">Bestellbare Artikel</h2>
                                <div class="card-tools">
                                    <div class="input-group input-group-sm">
                                        <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="getAllArticles" v-model="search" />
                                        <button type="submit" class="btn btn-default btn-sm" @click.prevent="getAllArticles">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body overflow-auto">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                                                <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            <th>Artikelname</th>
                                            <th>Preis</th>
                                            <th>Flags</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(article,index) in articles.data" :key="article.id" :class="article.article.active == 0 ? 'table-danger' : ''" @dblclick="addArticle(index)">
                                            <td>{{ article.ordernumber }}</td>
                                            <td>{{ generateName(index) }}</td>
                                            <td>{{ article.price | toCurrency}}</td>
                                            <td>
                                                <span class="ml-2 badge bg-primary" v-if="article.laststock == 1">AdS</span>
                                                <span class="ml-2 badge bg-danger" v-if="article.not_available == 1">z.Z.n.l.</span>
                                                <span class="ml-2 badge bg-success" v-if="article.mhd != null">MHD</span>
                                                <span class="ml-2 badge bg-warning" v-if="article.article.seasonal == 1">Saison </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="clearfix align-middle card-footer">
                                <div class="row">
                                    <div class="col-md-12">
                                        *rot hinterlegte Artikel sind deaktivierte Artikel. Sie können aber bei dem Lieferanten bestellt werden!
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <pagination class="float-left" :data="articles" @pagination-change-page="getAllArticles" :limit="3"></pagination>
                                
                                        <select class="float-right form-control-sm" v-model="params.per_page">
                                            <option value="25">25 Artikel</option>
                                            <option value="50">50 Artikel</option>
                                            <option value="75">75 Artikel</option>
                                            <option value="100">100 Artikel</option>
                                            <option value="125">125 Artikel</option>
                                            <option value="150">150 Artikel</option>
                                        </select>
                                        <p v-if="notEmptyObject(articles)" class="float-right mr-2">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card card-success card-outline" style="height: 45vh">
                            <div class="card-header">
                                <h2 class="card-title">Bestellte Artikel</h2>
                                <div class="card-tools">
                                    <button class="btn btn-primary btn-sm" @click="addFreeArticle"><i class="fas fa-plus"></i> Freie Position</button>
                                </div>
                            </div>
                            <div class="card-body overflow-auto">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>HAN</th>
                                            <th>Artikelnummer</th>
                                            <th>Artikelname</th>
                                            <th>Anzahl</th>
                                            <th>Aktion</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(article,index) in order.items" :key="article.id">
                                            <td>
                                                <input v-if="article.type == 'free'" type="text" v-model="order.items[index].han" class="form-control form-control-sm" />
                                                <span v-else>{{ article.han }}</span>
                                            </td>
                                            <td>{{ article.ordernumber }}</td>
                                            <td>
                                                <input v-if="article.type == 'free'" type="text" v-model="order.items[index].name" class="form-control form-control-sm" />
                                                <span v-else>{{ article.name }}</span>
                                            </td>
                                            <td><input type="number" class="form-control form-control-sm" v-model="order.items[index].qty" /></td>
                                            <td>
                                                <input v-if="article.type == 'free'" type="number" v-model="order.items[index].purchase_price" class="form-control form-control-sm" />
                                                <span v-else>{{ article.purchase_price | toCurrency }}</span>
                                            </td>
                                            <td><button type="button" class="btn btn-danger btn-sm" @click="deleteArticle(index)"><i class="fas fa-trash"></i></button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer text-right">
                                <p><strong>Bestellwert:</strong> {{ calculateEK() | toCurrency}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <div class="row">
                    <div class="col-md-12 text-right" v-if="notEmptyObject(order)">

                        <button type="button" class="btn btn-primary mr-1" @click="editOrder(false)" :disabled="order.items.length <= 0" >Speichern</button>
                        <button type="button" class="btn btn-danger mr-1" @click="editOrder(true)" v-if="order.distributor.send_email == 1" :disabled="order.items.length <= 0">Speichern & Senden</button>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: "SupplierOrdersDetails",
    title: "Lieferantenbestellung anpassen",

    data(){
        return {
            order: {},
            articles: {},
            params: {
                sort_field: 'ordernumber',
                sort_direction: 'desc',
                per_page: 25,
                distributor_id: '',
            },
            search: '',
        }
    },

    watch: {
        params: {
            handler () {
                this.getAllArticles();
            },
            deep: true
        },
        'order.items': {
            handler () {
                this.calculateEK();
            },
            deep: true
        }
    },

    methods:{
        calculateEK(){
            var sum = 0;

            if(this.order.items.length > 0){
                this.order.items.forEach(function (item){
                    sum += (item.purchase_price * item.qty)
                });
            }

            return sum;

        },
        addFreeArticle(){
            this.order.items.push({
                han: 'FP',
                ordernumber: 'FP',
                qty: '1',
                name: 'Freie Position',
                purchase_price: 0,
                type: 'free',
            })
        },

        setOrdered(){
            this.$swal({
                    title: "Auftrag als bestellt markieren?",
                    text: "Achtung! Wenn der Auftrag als bestellt markiert worden ist, wird die Bestellung nicht an den Lieferanten übermittelt. Die Bestellung wird gesperrt und kann nicht mehr verändert werden.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Als "bestellt" markieren`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.axios
                        .post("/supplier-orders/" + this.$route.params.id + '/set/ordered')
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Bestellung erstellt",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.$router.push({name: 'supplier-orders.index'});
                        });
                    }
                });        
        },

        editOrder(send){
            if(send == true)
            {
                this.order.autoSend = 1;
            }
            else
            {
                this.order.autoSend = 0;
            }
            this.axios
            .put("/supplier-orders/" + this.$route.params.id, 
                this.order,
            )
            .then(() => {
                this.$swal({
                    icon: "success",
                    title: "Bestellung erstellt",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    });
                if(send == true)
                {
                    this.$router.push({name: 'supplier-orders.index'});
                }
                else
                {
                    this.loadSupplierOrder();
                }    
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.$Progress.fail();
            });
        },

        deleteArticle(index){
            this.$delete(this.order.items, index);
        },

        addArticle(index){
            var article = this.order.items.find(item => item.ordernumber == this.articles.data[index].ordernumber);
            if(article == undefined)
            {
                this.order.items.push({
                    ordernumber: this.articles.data[index].ordernumber,
                    name: this.generateName(index),
                    producer: this.articles.data[index].article.producer.name,
                    price: this.articles.data[index].price,
                    qty: 1,
                    purchase_price: this.articles.data[index].purchase_price,
                    article_detail_id: this.articles.data[index].id,
                    type: 'article',
                    unit_name: this.articles.data[index].purchaseunit + ' ' + this.articles.data[index].unit.description,
                    han:  this.articles.data[index].han
                });    
            }
            else
            {
                article.qty += 1;
            }
        },

        loadSupplierOrder(){
            this.$Progress.start();
            this.axios
                .get("/supplier-orders/" + this.$route.params.id)
                .then((response) => {
                    this.order = response.data.data;
                    this.params.distributor_id = this.order.distributor_id;
                    this.getAllArticles();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getAllArticles(page = 1) {
            this.$Progress.start();
            this.axios
                .get("/details", {
                    params: {
                        page,
                        search: this.search.length >= 4 ? this.search : '',
                        ...this.params
                    }
                })
                .then((response) => {
                    this.articles = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        generateName(index)
        {
            var article = this.articles.data[index];
            var name = article.article.name;

            article.values.forEach(function (value) {
                name = name + ' - ' + value.name;
            });

            return name;
            
        },
    },

    created(){
        this.loadSupplierOrder();
        
    }

}
</script>

<style>

</style>